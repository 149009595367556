import { styled, experimental_sx as sx } from '@mui/system';

export default styled('button', {
  name: 'Button',
  overridesResolver({ color, theme }, styles) {
    return [sx({
      fontFamily: 'inherit',
      fontWeight: 'medium',
      fontSize: '0.875rem',
      padding: '12px 24px',
      borderRadius: '12px',
      color: 'inherit',
      transition: 'all 150ms ease',
      cursor: 'pointer',
      border: 'none',
      backgroundColor: 'transparent'
    }), sx(styles.root)];
  },
})`
`;
