import React from "react";
import * as yup from "yup";
import { Box, Stack } from "@mui/system";
import { Formik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Field from "./Field";
import Button from "./Button";
import ErrorMessage from "./ErrorMessage";
import { useTranslation } from "react-i18next";
import ServerResponseError from "./ServerResponseError";
import Heading from "./Heading";

export default function CodeLogin() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [error, setError] = React.useState(null);

  const schema = yup.object().shape({
    code: yup.string().required("Detta är ett obligatoriskt fält"),
  });

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        code: "",
      }}
      onSubmit={async ({ code }) => {
        setError(null);
        try {
          let response = await fetch("/api/login/verify-code", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ code }),
            cache: "no-cache",
          });
          const result = await response.json();
          if (!response.ok) {
            throw new ServerResponseError(result);
          }
          navigate("/complete");
        } catch (e) {
          setError(e);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Box
          sx={{
            p: [3, 4],
            bgcolor: "surface.light",
            m: [1, 2, 4],
            alignSelf: "center",
            justifySelf: "center",
            width: "25rem",
            maxWidth: "100%",
            boxSizing: "border-box",
          }}
        >
          <Form>
            <Stack spacing={2}>
              <div>
                <Heading>{t("siteTitle")}</Heading>
                <p>
                  Vi har skickat ett mejl till dig med instruktioner på hur du
                  loggar in. Klicka på länken i mejlet eller fyll i koden här
                  nedan för att fortsätta.
                </p>
                <p>
                  Inte fått någon kod? <Link to="/">Prova igen</Link>
                </p>
              </div>
              <Field
                name="code"
                placeholder="_ _ _ _ _ _"
                required={true}
                disabled={isSubmitting}
                sx={{
                  display: "block",
                  justifySelf: "stretch",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "2rem",
                  p: 2,
                }}
                autoComplete="off"
              />
              <Button
                type="submit"
                disabled={isSubmitting}
                sx={{
                  display: "block",
                  justifySelf: "stretch",
                  width: "100%",
                  p: 2,
                }}
              >
                {t("action.continue")}
                {" →"}
              </Button>
              <ErrorMessage error={error}></ErrorMessage>
            </Stack>
          </Form>
        </Box>
      )}
    </Formik>
  );
}
