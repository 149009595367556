import { styled } from "@mui/system";
import React from "react";

const StyledSpan = styled("span")`
  display: inline-block;
  vertical-align: ${(props) => props.verticalAlign || "-15%"};
  font-size: ${(props) => props.size || "1em"};
  background-color: ${(props) => props.color || "currentColor"};
  width: 1em;
  height: 1em;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  mask-image: url(${(props) => props.src});
`;

export default function Icon({ children, color, src, size, ...restProps }) {
  void children;
  if (!src) {
    return null;
  }

  return <StyledSpan color={color} size={size} src={src} {...restProps} />;
}
