import { Box, Stack } from "@mui/system";
import { Formik, Form } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import Field from "./Field";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import ErrorMessage from "./ErrorMessage";
import ServerResponseError from "./ServerResponseError";
import Heading from "./Heading";
import logo from "./icons/logo.svg";
import { styled } from "@mui/system";

const Image = styled("img")`
  margin-bottom: 2rem;
`;

export default function EmailLogin() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = React.useState(null);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={async ({ email }) => {
        setError(null);
        try {
          let response = await fetch("/api/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
            cache: "no-cache",
          });
          const result = await response.json();
          if (!response.ok) {
            throw new ServerResponseError(result);
          }
          navigate("/verify-code");
        } catch (e) {
          setError(e);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Box
          sx={{
            p: [3, 4],
            bgcolor: "surface.light",
            m: [1, 2, 4],
            alignSelf: "center",
            justifySelf: "center",
            width: "25rem",
            maxWidth: "100%",
            boxSizing: "border-box",
          }}
        >
          <Form>
            <Stack spacing={2}>
              <div>
                <Image src={logo} alt="logo" />
                <Heading>{t("siteTitle")}</Heading>
                <p>Logga in med din e-postadress</p>
              </div>
              <Field
                name="email"
                type="email"
                placeholder={t("emailExample")}
                required={true}
                disabled={isSubmitting}
                sx={{
                  display: "block",
                  justifySelf: "stretch",
                  width: "100%",
                  boxSizing: "border-box",
                  p: 2,
                }}
              />
              <Button
                type="submit"
                disabled={isSubmitting}
                sx={{
                  display: "block",
                  justifySelf: "stretch",
                  width: "100%",
                  p: 2,
                }}
              >
                {t("action.logIn")}
                {" →"}
              </Button>
              <ErrorMessage error={error}></ErrorMessage>
            </Stack>
          </Form>
        </Box>
      )}
    </Formik>
  );
}
