import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/system";
import Heading from "./Heading";

export default function LoadingScreen() {
  const { t } = useTranslation();

  useEffect(() => {
    window.location.reload();
  }, []);

  return (
    <Box
      sx={{
        p: [3, 4],
        bgcolor: "surface.light",
        m: [1, 2, 4],
        alignSelf: "center",
        justifySelf: "center",
        width: "25rem",
        maxWidth: "100%",
        boxSizing: "border-box",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          "& > :first-child": {
            mt: 0,
          },
        }}
      >
        <div>
          <Heading>{t("siteTitle")}</Heading>
          <p className={"description"}>Du loggas in…</p>
        </div>
      </Stack>
    </Box>
  );
}
