import { ThemeProvider } from "@mui/system";

import { createMemoryRouter, RouterProvider } from "react-router-dom";
import EmailLogin from "./EmailLogin";
import CodeLogin from "./CodeLogin";
import LoadingScreen from "./LoadingScreen";

import theme from "./theme";

const router = createMemoryRouter([
  {
    path: "/",
    element: <EmailLogin />,
  },
  {
    path: "/verify-code",
    element: <CodeLogin />,
  },
  {
    path: "/complete",
    element: <LoadingScreen />,
  },
]);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
