import React from "@emotion/react";
import { Box, Stack, styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { camelCase } from "lodash-es";
import errorIcon from "./icons/error.svg";
import Icon from "./Icon";

const Content = styled("p")`
  margin: 0;
  align-self: center;
  white-space: pre-line;
`;

const Link = styled("a")`
  color: inherit;
`;

export default function ErrorMessage({ error, ...restProps }) {
  const { t } = useTranslation();
  if (!error) {
    return null;
  }
  return (
    <Box
      sx={{ bgcolor: "error.main", color: "error.contrastText", p: 2 }}
      {...restProps}
    >
      <Stack direction={"row"} spacing={1}>
        <Icon src={errorIcon} size="1.75rem" sx={{ flex: "none" }} />
        <Content>
          {error.code === "FST_EMAIL_AUTH_UNAUTHORIZED_EMAIL" ? (
            <>
              <strong>{t("error.oops")}</strong>
              {t("error.fstEmailAuthUnauthorizedEmailOne")}
              <Link href="mailto: medlem@sgbc.se">medlem@sgbc.se</Link>
              {t("error.fstEmailAuthUnauthorizedEmailTwo")}
            </>
          ) : error.code ? (
            <>{t([`error.${error.code && camelCase(error.code)}`])}</>
          ) : (
            <>
              {t("error.unknown")}&nbsp;Kontakta vår support på&nbsp;
              <Link href="mailto: medlem@sgbc.se">medlem@sgbc.se</Link>
            </>
          )}
          {process.env.REACT_APP_SHOW_ERROR_CODES === "true" && !!error.code && (
            <>
              <br />
              <br />
              <small>
                <code>{error.code}</code>
              </small>
            </>
          )}
        </Content>
      </Stack>
    </Box>
  );
}
