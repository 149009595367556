import { createTheme } from "@mui/system";

export default createTheme({
  palette: {
    surface: {
      light: "#fff",
    },
    primary: {
      main: "#196719",
      contrastText: "#fff",
    },
    success: { main: "#36b37e", contrastText: "#fff" },
    warning: { main: "#FFF9B2", contrastText: "#000" },
    info: { main: "#0065ff", contrastText: "#fff" },
    error: { main: "#FFF9B2", contrastText: "#000" },
    danger: { main: "#de350b", contrastText: "#fff" },
  },
  components: {
    Button: {
      styleOverrides: {
        root: {
          padding: ".5rem",
          borderRadius: ".25rem",
          fontSize: "1rem",
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          "&:hover:not(:disabled)": {
            filter: "brightness(1.1)",
          },
          "&:active": {
            transform: "scale(0.95)",
          },
        },
      },
      defaultProps: {
        color: "primary",
      },
    },
  },
});
